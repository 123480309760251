








import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'VRoot',
  props: ['headerRef', 'footerRef'],
  setup() {
    const enter = (el: HTMLElement, done: () => void) => {
      // eslint-disable-next-line quotes
      console.log("💫 - ANIMATION D'ENTREE DE LA PAGE - 💫")
      done()
    }

    return {
      enter,
    }
  },
})
