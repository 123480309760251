



































































































































import { defineComponent, ref, onMounted } from '@vue/composition-api'
import axios from 'axios'

const positions = ['right', 'left'] as const

type EnvsData = Record<string, Record<string, string>>
type EnvsValues = Record<string, string>
type EnvsPosition = typeof positions[number]

export default defineComponent({
  name: 'env-switcher',
  props: {
    position: {
      type: String as () => EnvsPosition,
      default: 'right',
      validator(pos: string) {
        return positions.includes(pos as EnvsPosition)
      },
    },
  },
  setup(props, ctx) {
    const isActive = process.env.VUE_APP_RELEASE === 'staging'
    const { $cookie, $logger } = ctx.root
    const isOpen = ref(false)
    const envs = ref<EnvsData>({})
    const currents = ref<EnvsValues>({})

    const onToggle = () => {
      isOpen.value = !isOpen.value
    }
    const onChange = (label: string) => {
      const cookieName = `epic_env_${label}`

      $cookie.set(cookieName, currents.value[label])
      window.location.reload()
    }

    onMounted(async () => {
      if (!isActive) {
        return
      }

      try {
        await axios.get('/envs.json').then(res => {
          const { data } = res
          const currentsData: EnvsValues = {}

          envs.value = { ...data }

          Object.keys(data).forEach(label => {
            const cookieName = `epic_env_${label}`

            if ($cookie.get(cookieName)) {
              currentsData[label] = $cookie.get(cookieName)
            } else {
              currentsData[label] = data[label].staging
                ? 'staging'
                : Object.keys(data[label])[0]
            }

            $cookie.set(cookieName, currentsData[label])
            if (label === 'front') {
              $cookie.set('epic_env', currentsData[label])
            }
          })

          currents.value = currentsData
        })
      } catch (error) {
        $logger.warn('No ENVS found')
      }
    })

    return {
      isActive,
      isOpen,
      envs,
      currents,
      onToggle,
      onChange,
    }
  },
})
