import { getOptions, STORAGE, StorageUtils } from '.'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function storageClient(ctx: any) {
  const { pluginRuntimeOptions } = ctx
  const { type } = getOptions(pluginRuntimeOptions)
  const storage = window[type] as Storage

  const storageUtils: StorageUtils = {
    get hasStorage() {
      return (type as STORAGE) !== STORAGE.NONE
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setItem(key: string, value: any) {
      const v = typeof value === 'string' ? value : JSON.stringify(value)

      this.hasStorage && storage.setItem(key, v)
    },
    getItem(key: string, parse = true) {
      if (this.hasStorage) {
        const value = storage.getItem(key)

        if (value) {
          return parse ? JSON.parse(value) : value
        }
      }

      return null
    },
    removeItem(key: string) {
      this.hasStorage && storage.removeItem(key)
    },
  }

  // Enhance ctx
  ctx.$storage = storageUtils
  ctx.rootOptions.$storage = storageUtils
}
