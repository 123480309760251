





















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    inline: {
      type: Boolean,
      default: false,
    },
  },
  name: 'g-loader',
})
