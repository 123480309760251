import { AxiosResponse } from 'axios'
import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'

import { cache, logger } from '@/inc/utils'
import { langDefault, getApiUrl } from '@/inc/app.config'
import { Language } from '@/inc/types'
import { RootState } from '@/store'

interface GlobalPage {
  title: string
  url: string
}

export interface Chrome {
  globalPages: Record<string, GlobalPage>
}

export interface ChromeState {
  data: Chrome
  currentLang: Language
}

const state: ChromeState = {
  data: {} as Chrome,
  currentLang: langDefault,
}

const getters: GetterTree<ChromeState, RootState> = {
  chrome: state => state.data,
  currentLang: state => state.currentLang,
}

const mutations: MutationTree<ChromeState> = {
  SET_CHROME(state, payload: Chrome) {
    if (payload) {
      state.data = payload
    }
  },
  UPDATE_LANGUAGE(state, lang: Language) {
    state.currentLang = lang
  },
}

const actions: ActionTree<ChromeState, RootState> = {
  async fetchChrome({ commit }, payload) {
    try {
      const { lang } = payload || langDefault
      const path = `${lang}/chrome`
      const response = (await cache.fetch(
        path,
        `${getApiUrl()}/chrome`
      )) as AxiosResponse<Chrome>
      const data: Chrome = response?.data

      commit('UPDATE_LANGUAGE', lang)
      commit('SET_CHROME', data)
    } catch (error) {
      logger.error('[fetchChrome]', error)
    }
  },
}

export const chrome: Module<ChromeState, RootState> = {
  state,
  getters,
  mutations,
  actions,
}
