












































import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import { parseRoute } from '@/inc/utils'

export default defineComponent({
  name: 'not-found',
  props: {
    customContent: Object,
    default: null,
  },
  setup(props, ctx) {
    const { $logger, $route } = ctx.root
    const { api, endpoint, resource } = parseRoute($route)
    const emoji = '¯\\_(ツ)_/¯'
    const content =
      props.customContent || useGetters(['chrome']).chrome.value.notFound

    $logger.warn('[NotFound]', emoji)

    return {
      content,
      url: `${api}/${endpoint}/${resource}`,
      emoji,
    }
  },
})
