






import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'g-wysiwyg',
  props: {
    wysiwyg: {
      type: String,
      default: '',
    },
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const runtimeComponent = computed(() => ({
      components: {},
      template: `<div>${props.wysiwyg || props.content?.htmltext}</div>`,
    }))

    return {
      runtimeComponent,
    }
  },
})
