import Vue, { VueConstructor } from 'vue'
import { RouteConfig } from 'vue-router'

// eslint-disable-next-line
import { langDefault, langAvailable, segments } from '@/inc/app.config'
import Root from '@/views/Root.vue'

// Format segments to (a|b)
// eslint-disable-next-line
const formatSegment = (key: string): string =>
  Array.isArray(segments[key])
    ? `(${(segments[key] as string[]).join('|')})`
    : (segments[key] as string)

let routes: RouteConfig[] = [
  {
    path: '/',
    name: 'Landing',
    component: () =>
      import(/* webpackChunkName: 'v-Home' */ '@/views/Landing.vue'),
    meta: {
      ssr: false,
      static: true,
    },
  },
  {
    path: '/:slug/:sub?',
    name: 'Page',
    component: () =>
      import(/* webpackChunkName: 'v-page' */ '@/views/Page.vue'),
    meta: {
      ssr: true,
    },
  },
  {
    path: '/*',
    name: 'All',
    component: () =>
      import(/* webpackChunkName: 'v-page' */ '@/views/Page.vue'),
    meta: {
      ssr: true,
    },
  },
]

// If multilanguage, add lang segment
if (langAvailable.length > 1) {
  const initialRoutes = [...routes]
  routes = [
    {
      path: `/:lang(${langAvailable.join('|')})`,
      component: Root as VueConstructor<Vue>,
      meta: {
        // ScrollTop all the time for all routes
        scrollTop: true,
      },
      children: initialRoutes
        .filter(r => r.meta?.root !== true)
        .map(r => {
          // Remove leading slash
          r.path = r.path.replace(/^\//, '')

          return r
        }),
    },
    {
      path: '/',
      redirect: () => `/${langDefault}/`, // `/${navigator.language.split('-')[0] || langDefault}`
    },
  ]

  routes = routes.concat(initialRoutes.filter(r => r.meta?.root === true))
}

export { routes }
