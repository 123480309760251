import { PromiseStatus, QueryablePromise } from '@/inc/types'

export function makeQueryablePromise<T>(
  promise: Promise<T>
): QueryablePromise<T> {
  // Don't modify any promise that has been already modified.
  if ((promise as QueryablePromise<T>).status) {
    return promise as QueryablePromise<T>
  }

  // Set initial status.
  let _status: PromiseStatus = 'pending'

  // Observe the promise, saving the fulfillment (or not) in a closure scope.
  const result: QueryablePromise<T> = promise.then(
    v => {
      _status = 'fulfilled'

      return v
    },
    e => {
      _status = 'rejected'

      throw e
    }
  ) as QueryablePromise<T>

  // Define status getter.
  Object.defineProperty(result, 'status', {
    get: () => _status,
  })

  return result
}
