/* eslint-disable @typescript-eslint/ban-types */
import { VNode } from 'vue'

interface OutsideItem {
  action: Function
  data?: Record<string, any> // eslint-disable-line @typescript-eslint/no-explicit-any
  el: HTMLElement
}

let hasListener = false
const items: OutsideItem[] = []
const clickHandler = (e: MouseEvent): void => {
  items
    .filter(item => item.data && item.data.isOpen)
    .filter(item => !item.el.contains(e.target as Node))
    .forEach(item => item.action())
}
const keyupHandler = (e: KeyboardEvent): void => {
  if (e.key !== 'Escape') {
    return
  }

  items.filter(item => item.data).forEach(item => item.action())
}

export default {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bind(el: HTMLElement, binding: any, vnode: VNode) {
    if (!hasListener) {
      document.addEventListener('click', clickHandler)
      document.addEventListener('keyup', keyupHandler)

      hasListener = true
    }

    items.push({
      action: binding.value,
      data: vnode.context?.$data,
      el,
    })
  },
  unbind(el: HTMLElement) {
    const [match] = items.filter(item => item.el === el)
    const index = items.indexOf(match)

    items.splice(index, 1)

    if (items.length === 0) {
      document.removeEventListener('click', clickHandler)
      document.removeEventListener('keyup', keyupHandler)

      hasListener = false
    }
  },
}
