






























































import {
  defineComponent,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  createElement as h,
} from '@vue/composition-api'
import { GActionContent, Icon } from '@/inc/types'

// Icon values
const defaultViewbox = '0 0 24 24'
const registeredIcons = {
  arrow: { symbol: 'arrows-large-right' },
  download: { symbol: 'icons-download' },
} as const

// Render functions
const props = {
  url: { type: String },
  to: { type: Object },
  target: { type: String },
  click: { type: Function },
  enter: { type: Function },
  leave: { type: Function },
}

const ActionAnchor = defineComponent({
  name: 'ActionAnchor',
  props,
  setup: (props, ctx) => () => (
    <a href={props.url} onMouseenter={props.enter} onMouseleave={props.leave}>
      {ctx.slots.default()}
    </a>
  ),
})

const ActionOutside = defineComponent({
  name: 'ActionOutside',
  props,
  setup: (props, ctx) => () => (
    <a
      href={props.url}
      target={props.target}
      onMouseenter={props.enter}
      onMouseleave={props.leave}
      rel="noopener noreferrer"
    >
      {ctx.slots.default()}
    </a>
  ),
})

const ActionRouterLink = defineComponent({
  name: 'ActionRouterLink',
  props,
  setup: (props, ctx) => () => (
    <router-link
      to={props.url || props.to}
      onMouseenter={props.enter}
      onMouseleave={props.leave}
    >
      {ctx.slots.default()}
    </router-link>
  ),
})

const ActionButton = defineComponent({
  name: 'ActionButton',
  props,
  setup: (props, ctx) => () => (
    <button
      type="button"
      onClick={props.click}
      onMouseenter={props.enter}
      onMouseleave={props.leave}
    >
      {ctx.slots.default()}
    </button>
  ),
})

export default defineComponent({
  name: 'g-action',
  props: {
    content: {
      type: Object as () => GActionContent,
      required: true,
      validator: (value: GActionContent) => {
        if (!value.to && (!value.tag || value.tag === 'a')) {
          !value.url &&
            console.warn('[GAction] url property is mandatory for links')

          return value.url !== undefined
        }

        return true
      },
    },
  },
  setup(props, ctx) {
    const clickHandler = (e: MouseEvent) => ctx.emit('on-click', e)
    const enterHandler = (e: MouseEvent) => ctx.emit('on-enter', e)
    const leaveHandler = (e: MouseEvent) => ctx.emit('on-leave', e)
    const {
      label,
      url = '',
      to = null,
      tag,
      target = '',
      icon: iconData,
      modifiers = [],
    } = props.content
    const isButton = tag === 'button'
    const isAnchor = to === null && url && /^(http|#)/.test(url)
    const isOutside = target === '_blank'

    if (!isButton) {
      modifiers.push('link')
    }

    let component = ActionRouterLink
    if (isButton) {
      modifiers.push('btn')
      component = ActionButton
    } else if (isOutside) {
      component = ActionOutside
    } else if (isAnchor) {
      component = ActionAnchor
    }

    const icon: Icon = {} as Icon
    if (iconData) {
      modifiers.push('icon')

      if (typeof iconData === 'string' && registeredIcons[iconData]) {
        modifiers.push(iconData as string)
        icon.name = registeredIcons[iconData].symbol || ''
        icon.viewbox = registeredIcons[iconData].viewbox || defaultViewbox
      } else {
        icon.name =
          (iconData as Icon).name ||
          registeredIcons[(iconData as Icon).slug || 'arrow']?.symbol
        icon.viewbox = (iconData as Icon).viewbox || defaultViewbox
        modifiers.push(icon.name)
      }
    }

    return {
      clickHandler,
      enterHandler,
      leaveHandler,
      component,
      label,
      url,
      to: to || {},
      target,
      icon,
      modifiers,
    }
  },
})
