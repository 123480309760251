// Webpack imports
import '@/styles/main.scss'

import Vue from 'vue'
import axios from 'axios'

// Plugins
// import Fragment from 'vue-fragment'
// Vue.use(Fragment.Plugin)
import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)

import hooks from '@u3u/vue-hooks'
Vue.use(hooks)

import VueAware from 'vue-aware'
Vue.use(VueAware)

import VueSvgSprite from 'vue-svg-sprite'
Vue.use(VueSvgSprite, { url: '' })

import VueEnterScreen from '@/inc/directives/enterScreen'
Vue.use(VueEnterScreen, {
  rootMargin: '0px',
})

// Components

// Directives
import VueOutside from '@/inc/directives/outside'
Vue.directive('outside', VueOutside)
import VueSrc from '@/inc/directives/src'
Vue.directive('src', VueSrc)

// Filters
import VFilters, {
  VFiltersOptions,
  capitalize,
  linebreak,
  modifiers,
  slugify,
  trimzeros,
} from '@monofront/v-filters'

Vue.use<VFiltersOptions>(VFilters, {
  capitalize,
  linebreak,
  modifiers,
  slugify,
  trimzeros,
})

import { langDefault } from '@/inc/app.config'
import i18n from '@/inc/i18n'
import { App, AppType } from '@/inc/types'
import { logger, pascalToKebab } from '@/inc/utils'

logger.info(process.env)

Vue.prototype.$logger = logger
Vue.prototype.$debug = false
Vue.config.productionTip = false

// Import all global components
// Btn.vue => <g-btn></g-btn>
const requireComponent = require.context('@/components/g', true, /.*\.vue$/)

requireComponent.keys().forEach(fileName => {
  let baseComponentConfig = requireComponent(fileName)

  baseComponentConfig = baseComponentConfig.default || baseComponentConfig

  const baseComponentName =
    baseComponentConfig.name ||
    `g-${pascalToKebab(fileName.replace(/^.+\//, '').replace(/\.\w+$/, ''))}`

  Vue.component(baseComponentName, baseComponentConfig)
})

import gsap from 'gsap'
import SplitText from '@/inc/vendors/gsap/SplitText'
gsap.registerPlugin(SplitText)

// Export factory function
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function createApp(ctx: any): App {
  const { type } = ctx as { type: AppType }
  const isApiRe = new RegExp(process.env.VUE_APP_API_PATH as string)

  logger.trace('[main:createApp]', type)

  // Set default base URL
  if (type === 'client') {
    axios.defaults.baseURL = window.location.origin
    Vue.prototype.$debug = window.location.search.includes('debug')
  }

  // Set default language
  axios.defaults.headers.common['Accept-Language'] = langDefault

  // Send ENV cookies to API (staging / branching)
  if (type === 'client' && process.env.VUE_APP_RELEASE === 'staging') {
    axios.interceptors.request.use(config => {
      const { epic_env_back: envBackCookie } = ctx.$cookie.getCookies()

      if (envBackCookie && isApiRe.test(config.url as string)) {
        logger.warn('ENV', config.url, envBackCookie)
        config.headers.cookie = `epic_env=${envBackCookie}`
      }

      return config
    })
  }

  /* eslint-disable @typescript-eslint/no-var-requires, global-require */
  const AppComponent = require('@/App.vue').default
  const ErrorComponent = require('@/Error.vue').default
  const createRouter = require('@/router').default
  const createStore = require('@/store').default
  /* eslint-enable @typescript-eslint/no-var-requires, global-require */

  const store = createStore(type)
  const router = createRouter(store)
  const app = {
    ErrorComponent,
    i18n,
    router,
    store,
    // This is necessary, it is for vue-meta
    head: {
      titleTemplate: '%s',
    },
    // This will expose `$root.$options.$resource` into components (server-side)
    $resource: ctx.$resource,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    render: (h: any) => h(AppComponent),
  }

  return app
}
