






















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'playCursor',
  data() {
    return {
      display: false,
      x: 0,
      y: 0,
    }
  },
  mounted() {
    window.addEventListener('mousemove', event => this.mousemove(event))
    window.addEventListener('cursorDisplay', () => this.cursorDisplay())
    window.addEventListener('cursorHide', () => this.cursorHide())
  },
  methods: {
    mousemove(event) {
      if (this.display === false) {
        return
      }
      this.x = event.clientX
      this.y = event.clientY
    },
    cursorDisplay() {
      if (this.display) {
        return
      }
      this.display = true
      document.body.classList.add('cursorless')
      this.mousemove(event)
    },
    cursorHide() {
      this.display = false
      document.body.classList.remove('cursorless')
    },
  },
  computed: {
    style() {
      return `transform:translate3d(${this.x}px, ${this.y}px, 0)`
    },
    classes() {
      return {
        'playCursor--visible': this.display,
      }
    },
  },
})
